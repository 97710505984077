<template>
    <div class="my-space">
        <div style="width: 1500px;margin: 0 auto;" :key="this.$route.params.id">
            <div class="header">
                <SpaceHead ref="head"></SpaceHead>
            </div>

            <div class="body" v-if="showMessage === false">
                <div class="left">
<!--                    <div class="left-top" v-if="shopInfo">-->
<!--                        <div class="angle"></div>-->
<!--                        <div style="padding: 0 20px 0 20px">-->
<!--                            <div style="display: flex; margin-bottom: 6px">-->
<!--                                <div style="margin-right: 20px;">-->
<!--                                    <img :src="shopInfo.logo.img_url" style="width: 66px; height: 66px; border-radius: 50%"/>-->
<!--                                </div>-->
<!--                                <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; margin-top: 2px">-->
<!--                                    <span style="font-size: 20px; font-weight: 600; color: #1C7EFF">{{shopInfo.name}}</span>-->
<!--                                    <div style="margin-top: 12px">-->
<!--                                        <span style="color: #ABABAB">好评率 </span>-->
<!--                                        <span>95% </span>-->
<!--                                        <span style="color: #ABABAB">成交量 </span>-->
<!--                                        <span>5357 </span>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div style="display: flex; justify-content: center; font-size: 16px; margin-bottom: 6px">-->
<!--                                <div v-if="$route.params.id === user.uuid" class="shop-btn hand op" @click="$router.push({path:'/shop/shopBackstage'})" style="border: 1px #F98B15 solid; color: #F98B15; margin-right: 10px">-->
<!--                                  <img src="@/assets/imgs/circle/backstage.png" style="width: 18px; height: 18px; margin-right: 6px; display: inline-block; vertical-align: middle">-->
<!--                                  <span style="vertical-align: middle">后台管理</span>-->
<!--                                </div>-->
<!--                              <div class="shop-btn hand op" @click="$router.push({path:'/shop/shopCode',query:{id:shopInfo.id}})" style="color: #FFFFFF; background-color: #F98B15">-->
<!--                                <img src="@/assets/imgs/circle/iconShop.png" style="width: 18px; height: 18px; margin-right: 6px; display: inline-block; vertical-align: middle">-->
<!--                                <span style="vertical-align: middle">进入店铺</span>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="left-top" v-if="!shopInfo && userInfo.uuid === user.uuid">-->
<!--                        <div class="angle"></div>-->
<!--                        <div style="padding: 0 30px 0 30px; text-align: center">-->
<!--                            <div style="font-size: 18px; margin-top: 20px; color: #999999; margin-bottom: 20px">暂无店铺信息</div>-->
<!--                            <div @click="toCreateShop" class="hand op" style="padding: 6px 12px; font-size: 16px; border: 1px #1C7EFF solid; border-radius: 10px">点击创建店铺</div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="left-data">
                        <div style="display: flex; justify-content: space-between">
                            <div style="font-size: 22px; font-weight: 600; margin-bottom: 20px">个人资料</div>
                            <div v-if="this.$route.params.id == user.uuid" class="hand op" style="color: #2970FF; margin-top: 2px; font-size: 16px; font-weight: 600" @click="showRight = 'card'">我的名片 ></div>
                        </div>
                        <div style="font-size: 16px">{{spaceInfo.userSpaceInfo.personalSignature}}</div>
                        <el-divider></el-divider>
                        <div class="left-info" v-if="spaceInfo.personalData">
                            <div v-if="spaceInfo.personalData.schoolInfo">
                              <div style="margin-bottom: 14px;" v-if="spaceInfo.personalData.schoolInfo.length > 0">
                                <span class="info-tag">毕业于：</span>
                                <span>{{spaceInfo.personalData.schoolInfo[spaceInfo.personalData.schoolInfo.length-1].schoolName}} {{spaceInfo.personalData.schoolInfo[spaceInfo.personalData.schoolInfo.length-1].major}}专业 {{spaceInfo.personalData.schoolInfo[spaceInfo.personalData.schoolInfo.length-1].degree}}学位</span>
                              </div>
                            </div>
                            <div style="margin-bottom: 14px" v-if="JSON.stringify(spaceInfo.userSpaceInfo.address) !== '{}'">
                                <span class="info-tag">所在地： </span>
                                <span style="color: #2970FF">{{spaceInfo.userSpaceInfo.address.cityName[0]}} / {{spaceInfo.userSpaceInfo.address.cityName[1]}}</span>
                            </div>
                            <div style="margin-bottom: 14px" v-if="spaceInfo.personalData.skills">
                                <span class="info-tag">业务技能： </span>
                                <span>{{spaceInfo.personalData.skills}}</span>
                            </div>
                          <div v-if="spaceInfo.personalData.workInfo">
                            <div style="margin-bottom: 14px" v-if="spaceInfo.personalData.workInfo.length > 0">
                              <span class="info-tag">工作经历： </span>
                              <span v-for="(item, index) in spaceInfo.personalData.workInfo" :key="index" style="margin-right: 6px; color: #2970FF; font-size: 16px;">{{item.companyName}}（{{item.position}}）</span>
                            </div>
                          </div>

                          <div v-if="spaceInfo.personalData.projectInfo">
                            <div style="margin-bottom: 14px" v-if="spaceInfo.personalData.projectInfo.length > 0">
                              <span class="info-tag">项目经历： </span>
                              <span v-for="(item, index) in spaceInfo.personalData.projectInfo" :key="index" style="margin-right: 6px; color: #2970FF; font-size: 16px;">{{item.name}}</span>
                            </div>
                          </div>

                          <div v-if="spaceInfo.personalData.honorInfo">
                            <div style="margin-bottom: 14px" v-if="spaceInfo.personalData.honorInfo.length > 0">
                              <span class="info-tag">获得荣誉： </span>
                              <span v-for="(item, index) in spaceInfo.personalData.honorInfo" :key="index" style="margin-right: 6px;color: #2970FF; font-size: 16px; line-height: 28px">{{item.name}}</span>
                            </div>
                          </div>

<!--                            <div style="margin-bottom: 14px" v-if="spaceInfo.personalData.professionalTitleInfo.length !== 0">-->
<!--                                <span class="info-tag">职业证书： </span>-->
<!--                                <span v-if="majorInfo.professionalTitleInfo[0].isOvert == '1'">{{majorInfo.professionalTitleInfo[0].name}}</span>-->
<!--                            </div>-->
<!--                            <div style="margin-bottom: 14px" v-if="majorInfo.NVQCartInfo.length !== 0">-->
<!--                                <span class="info-tag">资格证书： </span>-->
<!--                                <span>{{majorInfo.NVQCartInfo[0].name}}</span>-->
<!--                            </div>-->
                          <div v-if="spaceInfo.personalData.otherInfo">
                            <div style="margin-bottom: 14px" v-if="spaceInfo.personalData.otherInfo.length > 0">
                              <span class="info-tag">其他证书： </span>
                              <span v-for="item in spaceInfo.personalData.otherInfo">{{item.name}}</span>
                            </div>
                          </div>

                            <div style="margin-bottom: 14px" v-if="spaceInfo.userSpaceInfo.label.length !== 0">
                                <span class="info-tag">个人标签：</span>
                                <div>
                                    <el-tag v-for="(item, index) in spaceInfo.userSpaceInfo.label" :key="index" effect="plain" size="medium" style="border-radius: 20px; margin-right: 12px; margin-top: 10px; font-size: 14px; color: #2970FF">{{ item }}</el-tag>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="left-card">
                        <div style="min-height: 340px">
                            <div style="font-size: 22px; font-weight: 600; margin-bottom: 16px">留言板</div>
                            <div style="margin-bottom: 10px">
                              <span v-if="spaceInfo.messageAndReply.myMessage">{{spaceInfo.messageAndReply.myMessage}}</span>
                              <span v-else>主人很懒，什么都没有说。</span>
                            </div>
                            <div v-if="$route.params.id  !== user.uuid">
                                <el-input
                                        type="textarea"
                                        resize="none"
                                        :rows="5"
                                        placeholder="给好友留言"
                                        v-model="messageContent"
                                        :autosize="{ minRows: 5, maxRows: 10 }"
                                        :show-word-limit="true"
                                        maxlength="300">
                                </el-input>
                                <div style="display: flex; justify-content: right; margin: 12px 0">
                                    <el-button class="save-btn" style="height: 30px; line-height: 2px" @click="upMessage($route.params.id )">发表</el-button>
                                </div>
                                <hr style="margin-bottom: 20px; border-top:2px dotted #999999"/>
                            </div>
                            <div v-for="(item, index) in spaceInfo.messageAndReply.MessageAndReplyList.slice(0 ,4)" :key="index" class="message-info">
                                <span style="color: #1C7EFF">{{item.commenterUserInfo.nickName}}：</span>
                                <span>{{item.content}}</span>
                                <div style="font-size: 14px; color: #999999; margin: 12px 0">{{item.time}}</div>
                                <hr style="margin-bottom: 20px; border-top:2px dotted #999999"/>
                            </div>
                        </div>
                        <div @click="showMessage = true" class="hand" style="color: #1C7EFF; margin: 20px 0 0 0">查看更多留言</div>
                    </div>
                </div>

                <div class="right" v-if="showRight === 'normal'">
                    <div class="right-top" style="position: relative" v-if="userInfo.authenticationStatus == 3">
                        <div class="yellow-angle"></div>
                        <div class="expert-info" style="font-size: 18px">
                          <div style="width: 100%;margin-bottom: 10px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                            <span style="color: #999999">专家头衔：</span>
                            <span v-for="(item, index) in expertInfo.rank" :key="index">{{item.name}} </span>
                          </div>
                          <div style="width: 100%; height: 58px; margin-bottom: 10px;display: -webkit-box; -webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">
                            <span style="color: #999999">专家介绍：</span>
                            <span>{{expertInfo.introduction}}</span>
                          </div>
                          <div style="width: 72%;margin-bottom: 10px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                            <span style="color: #999999">专家话题：</span>
                            <span style="color: #2970FF" v-for="(item, index) in expertTopicList" :key="index">#{{item.title}}# </span>
                          </div>

                          <div style="display: flex; position: absolute; bottom: 20px; right: 20px">
                              <div class="hand op" @click="b('/expert/info/' + userInfo.uuid)" style="color:#999999; margin-right: 20px; font-size: 16px; margin-top: 18px">查看更多 ></div>
                              <div class="hand op" @click="b('/expert/info/' + userInfo.uuid)" style="width: 162px; height: 36px; line-height: 36px; text-align: center; border-radius: 20px; font-size: 16px; background-color: #F98B15; color: #fff; margin-top: 10px">进入专家空间</div>
                          </div>
                        </div>
                    </div>

                    <div class="right-top" v-if="user.uuid === $route.params.id">
                      <div style="padding: 20px; position: relative">
                        <el-button style="position: absolute; right: 20px" size="small" v-if="user.userType < 3" type="primary" class="bottom" @click="utils.a('/member')">购买空间容量 ></el-button>
                        <SpaceBar v-if="JSON.stringify(spaceInfo.spaceSize) !== '{}'" :spaceSize="spaceInfo.spaceSize"></SpaceBar>
                      </div>
                    </div>

                    <div class="right-tabs">
                        <div style="display: flex">
<!--                            <div style="padding: 10px;cursor: pointer; margin-top: 6px" @click="counterStart"><i class="el-icon-caret-left"></i></div>-->
                            <div class="tab up" v-for="(item, index) in personalMenu" :key="index">
                                <label v-if="countStart <= index && index <= countEnd">
                                    <div class="head_white hand">
                                        <div :class="indexTags === item.key ? 'head_white_word_act': 'head_white_word'" @click="selectTags(item.key)">{{item.value}}</div>
                                        <div class="head_white_line"  v-if="indexTags === item.key"></div>
                                    </div>
                                </label>
                            </div>

                        </div>

<!--                        <div style="padding: 10px;cursor: pointer; margin-top: 6px" @click="counterEnd"><i class="el-icon-caret-right"></i></div>-->
                    </div>

                    <div class="right-select" v-if="indexTags === 'circle' || indexTags === 'article'">
                        <div class="select-left">
                          <div>
                            <el-select size="small" @change="selectOption" style="width: 300px" v-model="indexSelect" placeholder="请选择">
                              <el-option
                                  v-for="item in indexTags === 'circle' ? contentOptions : articleOptions"
                                  :key="item.key"
                                  :label="item.value"
                                  :value="item.key">
                              </el-option>
                            </el-select>
                          </div>
                        </div>
                    </div>

                    <div class="content-info" ref="content">
                      <div style="overflow:hidden;" v-if="loading">
                        <div v-for="(item,index) in 10" v-bind:key="index"
                             style="width: 100%;height: auto;">

                          <el-skeleton
                              style="width: 90%;background-color: white;padding: 5%; margin-bottom: 10px;"
                              :loading="loading" animated>
                            <template slot="template">
                              <div style="overflow:hidden;">
                                <el-skeleton-item variant="image"
                                                  style="width: 60px; height: 60px;border-radius:30px;float: left;"/>
                                <div style="float:left;display: flex; align-items: center; justify-content: space-between;">
                                  <el-skeleton-item variant="text" style="width: 50%;"/>
                                  <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 16px; height: 16px;">
                                    <el-skeleton-item variant="text"
                                                      style="width: 100px; margin: 12.5px 10px ; height:15px;"/>
                                    <el-skeleton-item variant="text"
                                                      style="width: 30px; margin: 12.5px 10px ; height:15px;"/>
                                  </div>
                                </div>
                              </div>
                              <el-skeleton-item variant="image"
                                                style="width: 90%; height: 210px;padding : 0 5%; float: left;margin-top: 10px;"/>

                              <el-skeleton-item variant="text" style="width: 50%;margin-top: 16px; "/>
                              <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 16px; height: 16px;">
                                <el-skeleton-item variant="text" style="margin-right: 16px;"/>
                                <el-skeleton-item variant="text" style="width: 30%;"/>
                              </div>
                            </template>
                          </el-skeleton>
                        </div>
                      </div>
                        <div v-else>
                            <div v-if="indexTags == 'circle'" style="width: 100%; overflow:hidden;">
                                <div v-for="(item,index) in content_list" v-bind:key="item.id" >
                                    <ContentALL :data="item" @deleteContent="deleteContent" :index="index"></ContentALL>
                                </div>
                            </div>

                            <div v-if="indexTags == 'article'" style="width: 100%; overflow:hidden;">
                              <div v-for="(item,index) in content_list" v-bind:key="item.id" >
                                <ContentALL :data="item" @deleteContent="deleteContent" :index="index"></ContentALL>
                              </div>
                            </div>

                            <div v-if="indexTags == 'message'" style="width: 96%; overflow:hidden; padding: 30px 20px 0 20px">
                                <div style="padding: 10px 20px" v-for="(item, index) in content_list" :key="index">
                                    <div style="display: flex">
                                        <div style="width: 100px;">
                                            <el-button v-if="item.type == 1" class="hand op" style="width: 66px; height: 22px; background-color: #4482FF; color: #fff; border-radius: 20px; line-height: 0;">招标</el-button>
                                            <el-button v-if="item.type == 2" class="hand op" style="width: 66px; height: 22px; background-color: #4482FF; color: #fff; border-radius: 20px; line-height: 0;">采购</el-button>
                                            <el-button v-if="item.type == 3" class="hand op" style="width: 66px; height: 22px; background-color: #4482FF; color: #fff; border-radius: 20px; line-height: 0;">招募</el-button>
                                            <el-button v-if="item.type == 4" class="hand op" style="width: 66px; height: 22px; background-color: #4482FF; color: #fff; border-radius: 20px; line-height: 0;">商务</el-button>
                                            <el-button v-if="item.type == 5" class="hand op" style="width: 66px; height: 22px; background-color: #4482FF; color: #fff; border-radius: 20px; line-height: 0;">招商</el-button>
                                            <el-button v-if="item.type == 6" class="hand op" style="width: 66px; height: 22px; background-color: #4482FF; color: #fff; border-radius: 20px; line-height: 0;">需求</el-button>
                                        </div>
                                        <div style="width: 56%; padding: 0 20px">
                                            <div style="line-height: 24px; font-size: 18px; font-weight: 600;">
                                                <span>{{item.title}}</span>
                                            </div>
                                            <div style="margin-top: 10px; display: flex; justify-content: space-between">
                                                <div>
                                                    <span>预算： </span>
                                                    <span style="font-weight: 600">{{item.other.budget}}</span>
                                                </div>
                                                <div>
                                                    <span>业务类型：</span>
                                                    <span style="font-weight: 600">{{item.typeName}}</span>
                                                </div>
                                                <div>
                                                    <span>工作地点：</span>
                                                    <span style="font-weight: 600" v-if="item.address">{{item.address.join('> ')}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width: 30%; height: 40px;">
                                            <div style="display: flex; justify-content: end; margin-right: 20px">
                                                <el-button @click="b('/index/details/' + item.id)" class="hand op" style="width: 106px; height: 36px; line-height: 0; font-weight: 600; margin-right: 10px; border: #1a1a1a 2px solid">查看详情</el-button>
                                            </div>
                                        </div>
                                    </div>
                                    <el-divider></el-divider>
                                </div>
                            </div>

                            <div v-if="indexTags == 'questions'" style="width: 94%; overflow:hidden; padding: 30px 30px 0 30px">
                                <div v-for="(item, index) in content_list" :key="index">
                                    <div style="display: flex">
                                        <div class="hand op" style="width: 80px; margin-left: 20px" @click="a('/index/page/?id=' + item.id)">
                                            <img src="../../assets/imgs/circle/red.png"/>
                                        </div>
                                        <div style="width: 90%;">
                                            <div class="re-title hand op" style="display: flex; justify-content: space-between" @click="a('/index/page/?id=' + item.id)">
                                                <div style="height: 24px; line-height: 24px; font-size: 18px; font-weight: 600;text-overflow:ellipsis ;overflow:hidden;white-space: nowrap;">{{item.title}}</div>
                                            </div>
                                            <div class="re-content">
                                                <div style="color: #666666; margin-top: 10px; font-size: 13px; display: flex; justify-content: space-between">
                                                    <div>
                                                        <span style="margin-right: 20px">{{item.TypeArrNameList[0]}} > {{item.TypeArrNameList[1]}} > {{item.TypeArrNameList[2]}}</span>
                                                        <span>{{item.createTime}}</span>
                                                    </div>
                                                    <div>
                                                        <span style="margin-right: 20px"><i class="el-icon-view"></i> {{item.reviewCount}}</span>
                                                        <span><i class="el-icon-chat-line-round"></i> {{item.clickCount}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <el-divider></el-divider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="right" v-if="showRight === 'card'">
                  <div>
                    <Card :userInfo="userInfo" @checkoutHome="checkoutHome"></Card>
                  </div>
                </div>
            </div>

            <div v-if="showMessage === true">
                <div class="data-message" style="padding: 30px">
                    <div style="font-size: 24px; font-weight: 600; color: #1479FF; display: flex">
                        <div class= "hand op" style="font-size: 22px; margin-right: 620px" @click="backHome">
                            <i class="el-icon-back" style="font-weight: 800; margin-right: 10px; vertical-align: middle"></i>
                            <span style="vertical-align: middle">返回</span>
                        </div>
                        <div>留言板</div>
                    </div>
                    <el-divider></el-divider>
                    <MessageBoard :uuid="$route.params.id" @onChange="getSpaceInfo"></MessageBoard>
                </div>
            </div>

            <el-dialog
                    :visible.sync="showVideo"
                    width="60%"
                    @close="showVideo = false"
                    center>
                <div v-if="showVideo === true">
                    <video-player class="video-player vjs-custom-skin" id="videoPlayer" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
                </div>
            </el-dialog>

            <el-dialog
                    :visible.sync="showPay"
                    width="40%"
                    @close="showPay = false"
                    title="红包问答详情"
                    center>
                <div>
                    <div style="display: flex; margin-bottom: 20px">
                        <span style="color: #999999; border: 1px #999999 solid; padding: 2px 4px; margin-right: 10px">{{questionInfo.TypeArrNameList[0]}} > {{questionInfo.TypeArrNameList[1]}} > {{questionInfo.TypeArrNameList[2]}}</span>
                        <span style="color: #FF5E5E; border: 1px #FF5E5E solid; padding: 2px 4px; margin-right: 10px">红包问答</span>
                    </div>

                    <div style="color: #031F88; margin-bottom: 20px">
                        <div style="font-size: 18px; margin-bottom: 10px; font-weight: 600">{{questionInfo.title}}</div>
                        <div style="font-size: 14px; margin-bottom: 10px">{{questionInfo.content}}</div>
                    </div>

                    <div style="display: flex; color: #999999">
                        <div style="margin-right: 10px">{{user.nickName}}</div>
                        <div style="margin-right: 10px">16587次浏览 | {{questionInfo.createTime}} 提问</div>
                        <img src="../../assets/imgs/circle/red.png" style="width: 20px; height: 20px; margin-right: 8px">
                        <div style="color: #FF5E5E">{{questionInfo.rewardMoney}}元 （有偿截止：{{questionInfo.endTime}}）</div>
                    </div>

                    <div style="display: flex; margin: 30px 0">
                        <div style="font-size: 15px; margin-right: 20px; margin-top: 4px"><span style="color:#FF0000">*</span>验证码</div>
                        <div>
                            <el-input name="code" v-model="code"  size="small" placeholder="请输入短信验证码" >
                                <template slot="append">
                                    <el-button style="width: 100%;height: 30px;border: 0;background: #F5F7FA" @click="getCode" :disabled="disabled">{{ codeString }}</el-button>
                                </template>
                            </el-input>
                        </div>
                    </div>

                    <div style="display: flex; justify-content: end">
                        <el-button @click="showPay = false" class="cancel-btn">取消</el-button>
                        <el-button @click="goPay" class="save-btn">确定</el-button>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import SpaceHead from "./components/SpaceHead";
    import Card from "./Card";
    import DatePicker from "./components/DatePicker";
    import ContentALL from "./components/ContentAll";
    import Dynamic from "./components/Dynamic";
    import Enclosure from "./components/Enclosure";
    import Visible from "./components/Visible";
    import NullBox from "./components/NullBox";
    import Expert from "./components/Authentication/Expert";
    import AddressSelect from "@/components/common/city/AddressSelect.vue";
    import AccountSetting from "@/pages/circle/components/AccountSetting.vue";
    import SpaceBar from "@/components/common/spaceBar.vue";
    import MessageBoard from "@/pages/circle/components/MessageBoard.vue";
    export default {
        name: "mySpace",
        components: {
          MessageBoard,
          SpaceBar,
          AccountSetting,
          AddressSelect,
            Expert,
            NullBox,
            Visible,
            Enclosure,
            Dynamic,
            ContentALL,
            DatePicker,
            Card,
            SpaceHead,
        },
        data() {
            return {
                spaceInfo: {
                  messageAndReply: {
                    MessageAndReplyList: []
                  },
                  personalData: {},
                  shopSpaceInfo: {},
                  spaceSize: {},
                  userSpaceInfo: {
                    address: {},
                    label: []
                  },
                  visitingCard: {}
                },

                options: [],
                newIndustry: [],
                props: { multiple: true},
                userUuid: this.$route.params.id,
                userInfo: {
                  address: {}
                },

                // 店铺信息
                shopInfo: null,

                // 留言板
                showMessage: false,
                isEdit: false,
                isReply: false,
                replayMessage: '',
                words: '',
                messageContent: '',
                messageListShow: [],
                messageList: [],

                showRight: 'normal',
                userLabel:[],
                myLabelEdit:false,
                myLabelAdd:false,
                myLabelValue:'',

                indexTags: 'circle',
                indexSelect: 0,
                contentOptions: [
                  {
                    key: 0,
                    value: '全部'
                  },
                  {
                    key: 1,
                    value: '动态'
                  },
                  {
                    key: 2,
                    value: '笔记'
                  },
                  {
                    key: 3,
                    value: '图片'
                  },
                  {
                    key: 4,
                    value: '视频'
                  },
                  {
                    key: 5,
                    value: '附件'
                  },
                ],
              articleOptions: [
                {
                  key: 0,
                  value: '全部'
                },
                {
                  key: 6,
                  value: '文章'
                },
                {
                  key: 7,
                  value: '图片'
                },
                {
                  key: 8,
                  value: '视频'
                },
                {
                  key: 9,
                  value: '附件'
                },
              ],

                show_name: 'resume',


                searchTime: '',
                searchContent: '',

                countStart: 0,
                countEnd: 8,
                personalMenu: [
                    {
                      key: 'circle',
                      value: '朋友圈'
                    },
                    {
                        key: 'article',
                        value: '知识仓库'
                    },
                    {
                        key: 'message',
                        value: '信息大厅'
                    },
                    {
                        key: 'questions',
                        value: '红包问答'
                    }
                ],


                topicInfo: [],
                expertInfo: {
                    introduction: "",
                    label: [],
                    sketch: [],
                    paper: {
                        monograph: [],
                        paper: []
                    },
                    rank: [],
                    topic: [],
                    other: []
                },

                expertData: {
                    introduction: "",
                    label: [],
                    sketch: [],
                    paper: {
                        monograph: [],
                        paper: []
                    },
                    rank: [],
                    topic: [],
                    other: []
                },

                nameInfo: {},

                like:'',
                text:'',
                content_list:[],
                //空间字段
                mySizeShow:false,
                countSize:0,

                // 视频播放
                showVideo: false,
                playerOptions: {
                    playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                    autoplay: false, // 如果为true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 是否视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4", // 类型
                        src: '' // url地址
                    }],
                    poster: '', // 封面地址
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true, // 当前时间和持续时间的分隔符
                        durationDisplay: true, // 显示持续时间
                        remainingTimeDisplay: false, // 是否显示剩余时间功能
                        fullscreenToggle: true // 是否显示全屏按钮
                    }
                },

                expertTopicList: [],

                page: 1,
                more:1,
                pageSize: 10,
                realNameAuth: 2,  //实名认证：0未认证1待审核2认证

                showPay: false,
                // 验证码状态
                disabled: false,
                codeString: '获取验证码',
                time: 60,
                code: '',
                questionInfo: {
                    TypeArrNameList: [],

                },
                codeType: '',
                topic: [],
                loading: false
            }
        },

        computed: {
            user() {
                return this.$store.state.user;
            },
            is_login(){
                return this.$store.state.is_login
            },
            router(){
                return this.$store.state.router
            }
        },

        watch: {
            $route: {
                handler() {
                    this.backTop();
                    this.userUuid = this.$route.params.id;
                    this.content_list = [];
                    this.page = 1;
                    this.more = 1;
                    this.showRight = 'normal'
                    this.getUser(this.$route.params.id);
                    this.getSpaceInfo(this.$route.params.id)
                    if (this.$route.query.type){
                      this.clickNavigation(this.$route.query.type);
                    } else {
                      this.clickNavigation(this.indexTags);
                    }
                    this.getLeaveMessageAndReplyList();
                }
            },

            'resumeForm.industry.value': {
                handler(newVal, oldVal) {
                    if (newVal.length > 5) {
                        this.$message({
                            message: '最多只支持选择5项',
                            duration: 1500,
                            type: 'warning'
                        });
                        this.$nextTick(() => {
                            this.resumeForm.industry.value = oldVal;
                        })
                    }
                }
            }
        },

        created() {
          this.getSpaceInfo(this.$route.params.id)
        },

        mounted() {
            if (this.$route.query.type){
                this.clickNavigation(this.$route.query.type);
            } else {
              this.clickNavigation(this.indexTags);
            }
            this.backTop();
            this.getUser(this.$route.params.id);
            this.userUuid = this.$route.params.id;
            //接收事件
            this.$EventBus.$on("changeData", ({ changeData }) => {
                this.$nextTick(() => {
                  this.page = 1;
                  this.more = 1;
                  this.content_list = [];
                  this.clickNavigation(this.indexTags);
                });
            });
            var that = this;
            window.onscroll = function(){
                //变量scrollTop是滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;

                //变量windowHeight是可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //变量scrollHeight是滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
                //滚动条到底部的条件
                var ratio = 0;
                var screen = window.screen;
                var ua = navigator.userAgent.toLowerCase();
                if (typeof window.devicePixelRatio !== 'undefined') {
                    ratio = window.devicePixelRatio;
                } else if (~ua.indexOf('msie')) {
                    if (screen.deviceXDPI && screen.logicalXDPI) { //IE
                        ratio = screen.deviceXDPI / screen.logicalXDPI;
                    }
                } else if (typeof window.outerWidth !== 'undefined' && typeof window.innerWidth !== 'undefined') {
                    ratio = window.outerWidth / window.innerWidth;
                }
                if(ratio <= 1){
                    if(scrollTop+windowHeight >= scrollHeight){
                        if (that.more == 1 &&  that.loading === false && that.showMessage === false) {
                            that.clickNavigation(that.indexTags);
                        }else{
                            console.log("已加载完");
                        }
                    }
                }else{
                    if(scrollHeight - scrollTop-windowHeight  < 10){
                        if (that.more == 1 && that.loading === false && that.showMessage === false) {
                            that.clickNavigation(that.indexTags);
                        }else{
                            console.log("已加载完");
                        }
                    }
                }
            }
        },

        methods: {
            //  留言返回
            backHome() {
              this.showMessage = false
              this.getSpaceInfo(this.$route.params.id)
            },
            // 发表留言
            upMessage(uuid) {
              this.newApi.addLeaveMessage({commenterUuid: uuid, content: this.messageContent}).then(res => {
                if (res.isSuccess == 1) {
                  this.getSpaceInfo(this.$route.params.id)
                  this.messageContent = '';
                  this.utils.sus(res.data)
                } else {
                  this.utils.err(res.data)
                }
              })
            },

            // 获取空间信息
            getSpaceInfo(uuid) {
              let params = {}
              params.uuid = this.user.uuid === uuid ? '' : uuid
              this.newApi.getSpaceInfo(params).then(res => {
                this.spaceInfo = res.data
                this.shopInfo = res.data.shopSpaceInfo
              })
            },

            toCreateShop() {
              if (!this.utils.toAuth(1)) {
                return false
              }
              this.utils.a('/shop/serVice/')
            },

            backTop () {
                document.documentElement.scrollTop = document.body.scrollTop = 0;

            },

            // 删除内容更新
            deleteContent(e) {
              this.content_list.splice(e, 1)
            },

            // 获取专家话题
            getExpertTopics() {
              this.newApi.getExpertTopic({uuid: this.user.uuid}).then(res =>{
                if (res.isSuccess == 1) {
                  this.topic = res.data
                }
              })
            },

            // 获取专家话题
            changeTopic(data) {
              this.topic = data;
            },

            // 创建快捷支付确认
            goPay(){
                var that = this;
                if (!that.code) {
                    that.utils.err('请填写验证码!')
                    return
                }
                var amn = {};
                amn.orderNo = that.questionInfo.bankPennyOrderNo;
                amn.code = that.code;
                that.newApi.settleAccountPennyDetermine(amn).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.utils.sus(ret.data);
                        that.content_list = [];
                        that.page = 1;
                        that.more = 1;
                        that.clickNavigation(that.indexTags);
                        that.showPay = false
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },

            //  创建支付对象申请
            getCode() {
                var that = this;
                var params = {};
                params.payAmt = that.questionInfo.rewardMoney.toFixed(2);
                params.applyId = that.codeType;
                params.orderNo = that.questionInfo.bankPennyOrderNo;
                params.payType = that.questionInfo.rewardType;  //支付类型1红包问答2悬赏资料3悬赏讨论4必选竞标
                that.newApi.settleAccountPenny(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus("请注意查收验证码");
                        that.changeCode();
                    }
                })
            },

            getAdaPayBanCardList(){
                var that = this;
                that.newApi.getAdaPayBanCardList({}).then((res)=>{
                    that.codeType =res.data[0].applyId;
                }).catch((err)=>{
                    console.log(err)
                })
            },

            changeCode(){
                var that = this;
                if(that.time == 0) {
                    that.disabled = false;
                    that.codeString ='获取验证码';
                    that.time  = 60;
                    return;
                } else {
                    that.disabled = true;
                    that.codeString = that.time + '秒后重新发送';
                    that.time --;
                }
                setTimeout(function(){
                    that.changeCode();
                }, 1000)

            },

            // 红包问答继续支付
            toPay(item) {
                this.questionInfo = item;
                this.getAdaPayBanCardList();
                this.showPay = true;
            },

            // 红包问答删除订单
            todel(id){
                var that = this;
                that.newApi.delNoPayQa({id:id}).then(res => {
                    that.utils.sus(res.data);
                })
            },

            // 获取专家话题
            getTopicList() {
                this.newApi.getExpertInfo({uuid: this.$route.params.id}).then(res => {
                    this.expertTopicList = res.data.topic
                })
            },

            // 转发后获取动态
            getForwardContent() {
                this.page = 1;
                this.more = 1;
                this.content_list = [];
                this.clickNavigation(this.indexTags)
            },

            // 视频播放准备
            playVideo(url, imgUrl) {
                this.showVideo = true;
                this.playerOptions.sources[0].src = url;
                this.playerOptions.poster = imgUrl;
            },

            // 跳转至详情页
            content_info(item){
              this.utils.b('/content/info/' + item.id + "/2");
            },

            // 点击打开好友空间
            openInfo(uuid, userType) {
                if (userType === 1) {
                    this.$router.push({ path: '/circle/mySpace/' + uuid});
                }
                if (userType === 2 || userType === 3) {
                    this.$router.push({ path: '/circle/organSpace/' + uuid});
                }
            },

            // 切换个人空间首页
            checkoutHome(e) {
                this.showRight = e
            },

            // 获取专家订单
            getOrderList() {
              this.newApi.getExpretOrderList({expert: this.$route.params.id, page: 1, pageSize: 4, status: 1}).then(res => {
                this.expertOrderList = res.data
              })
            },

            // 获取用户信息
            getUser(uuid) {
                this.newApi.getUserInfo({uuid: uuid}).then(res =>{
                    this.realNameStatus = res.data.realNameStatus;
                    this.majorAuthStatus = res.data.majorAuthStatus;
                    this.expertAuthStatus = res.data.expertAuthStatus;
                    this.userInfo = res.data;
                    if (res.data.authenticationStatus == 3) {
                        this.getOrderList();
                        this.getTopicList();
                    }
                })
            },

            // 切换类型
            selectOption() {
              this.content_list = [];
              this.page = 1;
              this.more = 1;
              this.clickNavigation(this.indexTags)
            },

            // 切换菜单
            selectTags(str) {
              this.indexSelect = 0;
              this.indexTags = str;
              this.content_list = [];
              this.page = 1;
              this.more = 1;
              this.clickNavigation(str)
            },

            // 获取动态
            clickNavigation(str){
                var that = this;
                var type = 0;
                that.loading = true;
                switch (str)
                {
                    case 'circle':type = 1;
                        break;
                    case 'article':type = 2;
                        break;
                    case 'message':type = 3;
                        break;
                    case 'questions':type = 4;
                        break;
                }
                if (that.loading) {
                    that.newApi.getSpaceContent({uuid: that.userUuid, type: type, page: that.page, pageSize: that.pageSize, bgbType: that.indexSelect}).then(res =>{
                      for (const item of res.data) {
                        that.content_list.push(item)
                      }
                      if (res.data.length < 10) {
                        that.more = 0;
                      }
                      that.page++;
                      that.loading = false;
                    })
                }
            },

            getLabel:function (uuid) {
                var that = this;
                that.newApi.getUserLabel({uuid: uuid}).then((res)=>{
                    that.userLabel = res.data;
                })
            },

            // 删除标签
            tagsClose(e) {
                this.userLabel.splice(e, 1)
            },

            // 新增标签
            handleInputConfirm() {
                let inputValue = this.myLabelValue;
                if (inputValue) {
                    this.userLabel.push(inputValue);
                }
                this.myLabelValue = '';
                this.myLabelAdd = false
            },

            // 获取搜索时间
            getSearchTime(e) {
                if (e) {
                    this.searchTime = e
                }
            },

            // 计数
            counterEnd() {
                if (this.countEnd < this.personalMenu.length - 1) {
                    this.countEnd ++;
                    this.countStart ++;
                }
            },
            counterStart() {
                if (this.countStart > 0) {
                    this.countEnd --;
                    this.countStart --;
                }
            },

            // 点击跳转名片编辑页
            cardEdit() {
                this.$refs.head.$emit('checkoutHead');
                var element = this.$refs['card'].offsetTop;
                document.documentElement.scrollTop = element + 400
            },
        }

    }
</script>

<style scoped>
    .header {
        margin: 0 0 10px 0;
        width: 100%;
        height: 370px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
    }

    .body {
        width: 100%;
        display: flex;
    }

    .data-body {
        width: 100%;
        display: flex;
        background-color: #fff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
        margin-bottom: 10px;
    }

    .data-message {
        width: 96%;
        background-color: #fff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
        margin-bottom: 10px;
    }

    .left {
        width: 336px;
        margin-right: 10px;
    }

    .left-top {
        width: 336px;
        height: 170px;
        background-color: #fff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
        margin-bottom: 10px;
    }

    .shop-btn {
      width: 140px;
      text-align: center;
      padding: 5px 0;
      border-radius: 30px;
    }

    .edit-left {
        width: 318px;
        padding: 20px 22px;
    }

    .left-data {
        width: 292px;
        min-height: 300px;
        padding: 20px 22px;
        background-color: #fff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
        margin-bottom: 10px;
    }

    .left-info {
        font-size: 16px;
    }

    .left-card {
        width: 292px;
        min-height: 400px;
        padding: 20px 22px;
        background-color: #fff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
        margin-bottom: 10px;
    }

    .left-picture {
        width: 292px;
        padding: 20px 22px;
        background-color: #fff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
        margin-bottom: 10px;
    }

    .left-friend {
        width: 292px;
        padding: 20px 22px;
        background-color: #fff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
        margin-bottom: 10px;
    }

    .angle {
        width: 0;
        height: 0;
        border: 18px solid;
        border-top-color: #1C7EFF;
        border-left-color: #1C7EFF;
        border-bottom-color: #fff;
        border-right-color: #fff;
    }

    .left-content {
        font-size: 16px;
        text-overflow:ellipsis;
        -webkit-line-clamp:2;
        -webkit-box-orient:vertical;
        overflow:hidden;
        display:-webkit-box;
    }

    .right {
        width: 1154px;
    }

    .edit-right {
        width: 1074px;
        padding: 22px 40px;
    }

    .right-top {
        width: 100%;
        height: 170px;
        margin-bottom: 10px;
        background-color: #fff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
    }

    .yellow-angle {
        width: 0;
        height: 0;
        position: absolute;
        left: 0;
        border: 18px solid;
        border-top-color: #F98B15;
        border-left-color: #F98B15;
        border-bottom-color: #fff;
        border-right-color: #fff;
    }

    .my-space .expert-info {
        padding: 20px 34px;
    }

    .my-space .expert-title {
        font-size: 18px;
        margin-right: 20px;
    }

    .my-space .experts-content {
        width: 240px;
        height: 30px;
        margin-right: 16px;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: top;
    }

    .right-tabs {
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2px;
        background-color: white;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)
    }

    .right-select {
        width: 100%;
        margin-bottom: 10px;
    }

    .select-left {
        display: flex;
        padding: 10px 20px;
        background-color: white;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)
    }

    .select-right {
        width: 534px;
        display: flex;
        padding: 14px 20px;
        background-color: white;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)
    }

    .tab {
        text-align: center;
        margin: auto 3%;
        float: left;
        text-align: center;
        cursor:pointer;
    }
    .up {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.1s;
    }
    .head_white {
        height: 26px;
        padding: 14.5px 0;
        width: 100px
    }
    .head_white_word {
        width: 100px;
        height: 26px;
        text-align: center;
        line-height:26px;
        color: black;
        font-size: 18px;
        font-weight: 600;
        float: left;
    }
    .head_white_word_act {
        width: 100px;
        height: 26px;
        text-align: center;
        line-height:26px;
        color: #2970FF;
        font-size: 18px;
        font-weight: 600;
        float: left;
    }
    .head_white_line {
        width: 60px;
        margin-left: 20px;
        height: 4px;
        background-color: #FF9800;
        border-radius: 5px;
        position: relative;
        margin-top: 10px;
        float: left;
    }

    .tags {
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .tags:hover {
        background-color: #F5F5F5;
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .info-tag {
        width: 88px;
        font-weight: 600;
        display: inline-block;
    }

    .resume-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .open-btn {
        width: 76px;
        height: 38px;
        padding: 2px 0;
        line-height: 6px;
        background-color: #E4E6EB;
        border-radius: 5px;
    }

    .save-btn {
        width: 68px;
        height: 38px;
        color: #fff;
        background-color: #1C7EFF;
        border-radius: 5px;
    }

    .cancel-btn {
        width: 68px;
        height: 38px;
        margin-right: 18px;
        background-color: #E4E6EB;
        border-radius: 5px;
    }

    .tags-child {
        height: 42px;
        line-height: 42px;
        margin-bottom: 2px;
    }

    .tags-style {
        border-radius: 20px;
        margin-right: 18px;
        font-size: 14px;
        color: #2970FF;
        vertical-align: middle;
    }

    .updateTags {
        width: 28px;
        height: 28px;
        margin-left: 30px;
        vertical-align: middle;
    }

    .input-new-tag {
        width: 160px;
        vertical-align: bottom;
        display: inline-block;
    }

    .search-input {
        width: 422px;
    }

    .search-btn {
        width: 114px;
        height: 32px;
        background-color: #F0F2F5;
        color: #1C7EFF;
        font-size: 16px;
        padding: 0;
        font-weight: 600;
    }

    .edit-model {
        margin-bottom: 100px;
    }

    .inner-container {
        max-height: 120vh;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .inner-container::-webkit-scrollbar {
        display: none;
    }

    .update-btn {
        width: 140px;
        height: 40px;
        text-align: center;
        color: #2970FF;
        font-size: 18px;
        padding: 2px 0;
        border: 1px #2970FF solid
    }

    .up-btn {
        width: 140px;
        text-align: center;
        color: #FFFFFF;
        font-size: 18px;
        border-radius: 5px;
        padding: 6px 0;
        background-color: #FF9800;
    }

    .sus-btn {
        height: 40px;
        color: #719F44;
        font-size: 18px;
        padding: 10px 10px;
        margin-left: 20px;
        border: 1px #719F44 solid
    }

    .message-info {
        font-size: 18px;
    }

    .user{
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
    }
    .user .userIco{
        width: 80px;
        height: 80px;
        background-color: #707070;
        border-radius: 50%;
        margin-right: 16px;
    }
    .user .major .userName{
        font-weight: 600;
        color: #1C7EFF;
        font-size: 22px;
        margin-top: 8px;
        margin-bottom: 20px;
        display: flex;
        line-height: 24px;
    }
    .major .userMajor{
        font-size: 18px;
        color: #999999;
        font-weight: 400;
        margin-left: 20px;
    }

    .major .message-content {
        min-height: 80px;
        font-size: 18px;
    }

    .major .message-time {
        font-size: 18px;
        color: #999999;
    }

    .content-info {
        width: 100%;
        margin-bottom: 10px;
        background-color: #fff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
    }

    /*.content-info::-webkit-scrollbar {*/
    /*    display: none;*/
    /*}*/

    /*公用及el*/
    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;
        transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    .sc:active{
        transform:scale(0.97);
    }

    .room_tab{
        margin-left: 10px;line-height: 51px;font-size: 15px;width: 80px;text-align: center;
    }
    .room_type_bottom{
        border-bottom: 4px solid #5A6FE6;font-weight: bold;
    }

    .info {
        margin-bottom: 20px;
    }

    .old-color {
        color: #FBFBFB;
    }

    .new-color {
      color: #5BC130;
    }

    /*下划线*/
    .el-divider--vertical {
        margin: 0;
        width: 2px;
        height: 100%;
    }

    .el-menu {
        border-right: none;
    }

    /deep/ .el-submenu__icon-arrow {
        display: none !important;
    }

    /deep/ .el-submenu__title {
        height: 42px;
        line-height: 42px;
    }

    /deep/ .el-submenu__title:hover {
        background-color: #F5F5F5;
        height: 42px;
    }

    .el-submenu__title i{
        color: #1a1a1a;
        line-height: 26px;
    }

    /deep/ .search-input .el-input__inner {
        height: 32px;
        background-color: #F0F2F5;
        border-radius: 50px;
        padding-left: 40px;

    }

    /deep/ .el-input__prefix {
        color: #1C7EFF;
        font-size: 18px;
        margin-top: 2px;
        font-weight: 600;
    }

    /deep/ .major-pic .el-dialog__header{
        padding: 0;
    }

    /deep/ .major-pic .el-dialog__body{
        padding: 0;
    }

</style>
