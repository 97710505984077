import { render, staticRenderFns } from "./mySpace.vue?vue&type=template&id=9da85822&scoped=true"
import script from "./mySpace.vue?vue&type=script&lang=js"
export * from "./mySpace.vue?vue&type=script&lang=js"
import style0 from "./mySpace.vue?vue&type=style&index=0&id=9da85822&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9da85822",
  null
  
)

export default component.exports